import React, {useCallback, useEffect, useState} from 'react'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {toast} from 'react-toastify'
import {yupResolver} from '@hookform/resolvers/yup'
import {
  Icon,
  ParagraphText,
  Input,
  TextArea,
  Checkbox,
  Button,
  withModal,
} from '@thryvlabs/maverick'
import {
  ModalContainer,
  InputContainer,
  TextAreaContainer,
  PurchaseIntentItem,
  PurchaseIntentInfoItem,
  PurchaseIntentContainer,
  PurchaseIntentListContainer,
  PurchaseIntentGroupContainer,
  OnboardingContainer,
} from '../components/ui/onboarding-program'
import {ADD_ON} from '../utils/addons-data'
import {NewOrderLayout} from '../components/new-order-layout'
import {CartPaymentDetails} from '../components/cart-payment-details'
import {useLocation} from 'react-router'
import {PhoneNumberUtil} from 'google-libphonenumber'
import {CcSidebar} from '../components/cc-sidebar'
import BookAppointments from '../components/appointments/book-appointments'

const phoneUtil = PhoneNumberUtil.getInstance()

const PURCHASE_INTENTS = [
  {
    title: 'Build Your Brand',
    description:
      'Initial onboarding will focus on your online presence - syncing listings, claiming GMB, connect website widgets, establishing an online reputation, creating social media profile/posting, building a professional website and more.',
    icon: 'https://lirp-cdn.multiscreensite.com/40644fd4111f4fc1929b5a8493d5708f/dms3rep/multi/opt/swatchbook_grey_orange_rgb-80w.png',
    options: [
      'Consistent Impression',
      'Be the Clear Choice',
      'Interact Easily',
    ],
  },
  {
    title: 'Stay Organized',
    description:
      'Initial onboarding will focus on centralizing your business with one Inbox and CRM – setting up calendar, appointments and scheduling, creating users and staff accounts, automated messages, creating estimates/invoices and more.',
    icon: 'https://lirp-cdn.multiscreensite.com/40644fd4111f4fc1929b5a8493d5708f/dms3rep/multi/opt/layer_group_grey_orange_rgb-80w.png',
    options: [
      'Simplify Your Business Operations',
      'Centralize Customer Info',
      'Automate Manual Tasks',
    ],
  },
  {
    title: 'Get Paid/Get Credit',
    description:
      'Initial onboarding will focus on getting paid faster - online payments, estimates/invoices, marketing automation, customer communication with text and email, review generation, social media posting and more.',
    icon: 'https://lirp-cdn.multiscreensite.com/40644fd4111f4fc1929b5a8493d5708f/dms3rep/multi/opt/dollar_sack_grey_orange_rgb-480w.png',
    options: [
      'Contact-less Transactions & Payments',
      'Get Credit',
      'Increase Revenue & Cash-Flow',
    ],
  },
  {
    title: 'Communicate',
    description:
      'Initial onboarding will focus on communicating more efficiently – Inbox, CRM and Client Portal, email sync, customer communication with text and email, marketing automation, connecting website widgets, follow-ups and reminders and more.',
    icon: 'https://lirp-cdn.multiscreensite.com/40644fd4111f4fc1929b5a8493d5708f/dms3rep/multi/opt/handshake_grey_orange_rgb-480w.png',
    options: [
      'Communicate with Customers on Their Terms',
      'Build & Maintain Relationships',
      'Create Supporters',
    ],
  },
]

const CODES_WITH_KICKOFF_CALL = [
  'bc_plus-5-thryv-m2m',
  'bc_pro-5-thryv-m2m',
  'bc_unlm-5-thryv-m2m',
  'mc_plus-5-thryv-m2m',
  'mc_pro-5-thryv-m2m',
  'seobase-5-thryv-m2m',
]

const ONBOARDING_REQUIRED_4PLAN_UPGRADES = [
  'addon-4-mc-m2m',
  'addon-4-mc-semi',
  'addon-4-mcpro-m2m',
]

const FREE_ACCOUNT_CODES = [
  'appmarket-5-thryv-m2m',
  'cc_basicpls-5-thryv-m2m',
  'cc_basic-5-thryv-m2m',
  'addon-5-signatures_usage',
  'app-websitebuilder',
]

const REQUIRED_FIELD_MESSAGE = 'This field is required'
const MIN_CHARACTERS_REQUIRED = 'Must be at least 3 characters'
const MAX_CHARACTERS_REQUIRED = 'Must be at most 120 characters'

function comparePlans(selectedCenters, defaultCenters, isFreeAccount) {
  // true -- no kickoff call
  // false -- kickoff call required
  const defaultCenterNames = defaultCenters?.map(({name}) => name)

  // Check if BC or MC is present
  const isBcSelected = selectedCenters?.find(
    ({name}) => name.split(' ')[0] === 'Business',
  )
  const isMcSelected = selectedCenters?.find(
    ({name}) => name.split(' ')[0] === 'Marketing',
  )
  const isBcAlreadyPresent = defaultCenters?.find(
    ({name}) => name.split(' ')[0] === 'Business',
  )
  const isMcAlreadyPresent = defaultCenters?.find(
    ({name}) => name.split(' ')[0] === 'Marketing',
  )

  if (
    (isBcSelected && !isBcAlreadyPresent && isMcAlreadyPresent) ||
    (isMcSelected && !isMcAlreadyPresent && isBcAlreadyPresent)
  )
    return true

  return selectedCenters?.every(({name}) => {
    const centerType = name.split(' ')[0]
    if (centerType === 'Reporting') return true

    return defaultCenterNames?.some(
      defaultCenterName =>
        defaultCenterName?.includes(centerType) &&
        !(name === 'Thryv Leads' && isFreeAccount),
    )
  })
}

const shouldDisplayKickOffCall = ({centers}) => {
  // true -- no kickoff call
  // false -- kickoff call required
  if (centers.some(center => center.name === 'Thryv Leads')) {
    return false
  }
  if (centers.some(center => CODES_WITH_KICKOFF_CALL.includes(center.code))) {
    return false
  }
  return true
}

const setIsKickoffNotRequiredCC = ({
  isUpgrade,
  centers,
  defaultCentersAndApps,
  isFreeAccount,
}) => {
  if (isUpgrade) {
    return comparePlans(
      centers?.filter(({name}) => !name.includes('SEO') || name === 'SEO'),
      defaultCentersAndApps?.centers,
      isFreeAccount,
    )
  }
  return shouldDisplayKickOffCall({centers})
}

const SCHEMA = yup.object().shape({
  firstName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  lastName: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .min(3, MIN_CHARACTERS_REQUIRED)
    .max(120, MAX_CHARACTERS_REQUIRED),
  email: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .email('Must be a valid email'),
  mobile: yup
    .string()
    .required(REQUIRED_FIELD_MESSAGE)
    .test(
      'validate-phone-format',
      'Incorrect phone number format',
      function (value) {
        try {
          if (!value) {
            return false
          }
          const country = this.options.context.componentProps.selectedCountry
          value = value.replace(/[^\d]/g, '')
          const parsedPhone = phoneUtil.parse(value, country)
          return phoneUtil.isValidNumber(parsedPhone)
        } catch {
          return false
        }
      },
    ),
  firstPurchaseIntent: yup.object().shape({
    option: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    icon: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    title: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
  }),
  secondPurchaseIntent: yup.object().shape({
    option: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    icon: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    title: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
  }),
  thirdPurchaseIntent: yup.object().shape({
    option: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    icon: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
    title: yup.string().when(['isUpgrade', 'CCOnboarding'], {
      is: false,
      then: yup.string().required(REQUIRED_FIELD_MESSAGE),
      otherwise: yup.string().notRequired(),
    }),
  }),
  csmExtraInfo: yup.string(),
  CCOnboarding: yup.boolean(),
  isUpgrade: yup.boolean(),
  isKickoffNotRequired: yup.boolean(),
  onboardingCallDate: yup.string().when(['isUpgrade', 'isKickoffNotRequired'], {
    is: false,
    then: yup.string(),
    otherwise: yup.string().notRequired(),
  }),
  showCreativeCall: yup.boolean(),
  creativeCallDate: yup.string().when('showCreativeCall', {
    is: true,
    then: yup.string(),
  }),
})

function PurchaseIntentOptionsList({
  close,
  prevStep,
  purchaseIntentInfo,
  setPurchaseItem,
  selectedPurchaseIntent,
  selectedPurchaseIntents,
}) {
  const [selectedOption, setSelectedOption] = useState(
    selectedPurchaseIntent || {},
  )

  const handleSelection = () => {
    setPurchaseItem(selectedOption)
    close()
  }

  return (
    <>
      <ParagraphText variant="lg" className="font-weight-bold">
        Step 2 - Pick a Subset
      </ParagraphText>
      <div className="d-flex justify-content-center align-items-center mb-2">
        <img
          src={purchaseIntentInfo.icon}
          alt="Purchase Intent Item Icon"
          width="20"
          className="mr-2"
        />
        <h4 className="m-0 font-weight-bold">{purchaseIntentInfo.title}</h4>
      </div>
      <ul className="p-0">
        {purchaseIntentInfo.options.map(option => {
          if (selectedPurchaseIntents.includes(option)) {
            return (
              <PurchaseIntentInfoItem key={option} className="mb-2 disabled">
                {option}
              </PurchaseIntentInfoItem>
            )
          }
          return (
            <PurchaseIntentInfoItem
              key={option}
              className={`mb-2 ${
                selectedOption.option === option ? 'selected' : ''
              }`}
              onClick={() =>
                setSelectedOption({
                  option,
                  icon: purchaseIntentInfo.icon,
                  title: purchaseIntentInfo.title,
                })
              }
            >
              {option}
            </PurchaseIntentInfoItem>
          )
        })}
      </ul>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Button variant="primary" onClick={prevStep}>
          Back
        </Button>
        <Button
          variant="primary"
          disabled={!selectedOption.option}
          onClick={handleSelection}
        >
          Save & Close
        </Button>
      </div>
    </>
  )
}

function PurchaseIntentList({close, nextStep}) {
  return (
    <PurchaseIntentListContainer>
      {PURCHASE_INTENTS.map(({title, description, icon, options}) => (
        <PurchaseIntentItem key={title}>
          <div className="d-flex justify-content-center align-items-center">
            <img
              src={icon}
              alt="Purchase Intent Item Icon"
              width="20"
              className="mr-2"
            ></img>
            <h4 className="m-0 font-weight-bold">{title}</h4>
          </div>
          <p>{description}</p>
          <div
            className="d-flex justify-content-center align-items-center purchase_intent_button"
            onClick={() => nextStep({title, icon, options})}
          >
            <ParagraphText
              variant="reg"
              color="thryv-orange-500"
              className="m-0 mr-2 font-weight-bold"
            >
              {title}
            </ParagraphText>
            <Icon
              type="solid"
              variant="arrowRight"
              color="#FF5000"
              height="15"
              width="15"
              className="m-0"
            />
          </div>
        </PurchaseIntentItem>
      ))}
    </PurchaseIntentListContainer>
  )
}
function Modal({
  close,
  setSelectedPurchaseIntent,
  selectedPurchaseIntents,
  selectedPurchaseIntent,
}) {
  const TOTAL_STEPS = 2
  const [purchaseIntentInfo, setPurchaseIntentInfo] = useState({})
  const [currentStep, setCurrentStep] = useState(1)

  const nextStep = data => {
    setPurchaseIntentInfo(data)
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }
  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }
  const setPurchaseItem = purchaseItem => {
    if (selectedPurchaseIntents.includes(purchaseItem)) {
      setSelectedPurchaseIntent(null)
      return
    }
    setSelectedPurchaseIntent(purchaseItem)
  }
  return (
    <ModalContainer>
      {currentStep === 1 && (
        <PurchaseIntentList nextStep={nextStep} close={close} />
      )}
      {currentStep === 2 && (
        <PurchaseIntentOptionsList
          nextStep={nextStep}
          prevStep={prevStep}
          purchaseIntentInfo={purchaseIntentInfo}
          setPurchaseItem={setPurchaseItem}
          close={close}
          selectedPurchaseIntents={selectedPurchaseIntents}
          selectedPurchaseIntent={selectedPurchaseIntent}
        />
      )}
    </ModalContainer>
  )
}

function PurchaseIntent({showModal, purchaseIntent, title, errors, name}) {
  if (purchaseIntent?.option) {
    return (
      <PurchaseIntentContainer onClick={showModal}>
        <div className="d-flex justify-content-center align-items-center mb-2">
          <img
            src={purchaseIntent.icon}
            alt="Purchase Intent Item Icon"
            width="20"
            className="mr-2"
          />
          <h4 className="m-0 font-weight-bold">{purchaseIntent.title}</h4>
        </div>
        <ParagraphText
          variant="reg"
          color="thryv-gray-medium-500"
          className="m-0"
        >
          {purchaseIntent.option}
        </ParagraphText>
      </PurchaseIntentContainer>
    )
  }

  return (
    <div>
      <PurchaseIntentContainer onClick={showModal}>
        <Icon
          type="solid"
          variant="circlePlus"
          color="#FF5000"
          className="mb-3"
        />
        <ParagraphText
          variant="reg"
          color="thryv-gray-medium-500"
          className="m-0"
        >
          {title}
        </ParagraphText>
      </PurchaseIntentContainer>
      {errors.errors[name]?.option.message && (
        <ParagraphText
          variant="reg"
          color="utility-error-danger"
          className="m-0 mt-2 text-center"
        >
          {errors.errors[name].option.message}
        </ParagraphText>
      )}
    </div>
  )
}

const PurchaseIntentModal = withModal(PurchaseIntent, Modal)

const OnboardingProgram = ({
  prevStep,
  nextStep,
  industryCategoryType,
  selectedAddons,
  accountInformation,
  creativeApptId,
  setCreativeApptId,
  selectedCountry,
  setKickoffApptId,
  kickoffApptId,
  onboardingCallDate,
  setOnboardingCallDate,
  creativeCallDate,
  setCreativeCallDate,
  setAllSelectedPurchaseIntent,
  allSelectedPurchaseIntent,
  selectedPlan,
  currentPlan,
  currentPlanName,
  currentStep,
  setCartProducts,
  cartProducts,
  selectedIndustryId,
  isWebsiteIncluded,
  isSocialBoostIncluded,
  selectedCentersAndApps,
  defaultCentersAndApps,
  incentiveCoupon,
  orderCouponCodes,
  upgradeAccountInfo,
  upgradePaymentPreview,
  previewRequestFinished,
  eligibleTransitionCenters,
  isCohort,
  order5NUId,
  isCancelOrder5,
  cancelOrder5,
  captureOrderNewUpgrade,
  order5OnboardingApptId,
  order5CreativeApptId,
  setOrder5CreativeApptId,
  setOrder5OnboardingApptId,
  isWebsiteValid,
  isNewTLMCAccessible,
  keapAccount = false,
}) => {
  const [useAccountOwnerInfo, setUseAccountOwnerInfo] = useState(true)
  const [firstPurchaseIntent, setFirstPurchaseIntent] = useState({})
  const [secondPurchaseIntent, setSecondPurchaseIntent] = useState({})
  const [thirdPurchaseIntent, setThirdPurchaseIntent] = useState({})
  const [displayCreativeCallModal, setDisplayCreativeCallModal] =
    useState(false)
  const [displayKickOffCall, setDisplayKickOffCall] = useState(true)
  const [onboardingConfirmed, setOnboardingConfirmed] = useState(false)
  const [creativeConfirmed, setCreativeConfirmed] = useState(false)
  const [continueDisabled, setContinueDisabled] = useState(true)
  const [isNewThryvLeads, setNewThryvLead] = useState(false) // new field inclusion for tl mc
  const {pathname} = useLocation()
  const {centers} = selectedCentersAndApps || {}
  const [isApptSkipped, setIsApptSkipped] = useState({
    kickoff: false,
    creative: false,
  })
  const isUpgrade = pathname === '/order/upgrade'
  const isFreeAccount = !(
    defaultCentersAndApps?.centers?.some(
      center => !FREE_ACCOUNT_CODES.includes(center.code),
    ) ||
    defaultCentersAndApps?.apps?.some(
      app => !FREE_ACCOUNT_CODES.includes(app.code),
    )
  )
  const isKickoffNotRequiredCC = setIsKickoffNotRequiredCC({
    isUpgrade,
    centers,
    defaultCentersAndApps,
    isFreeAccount,
  })
  const {mobile, email, lastName, firstName} = accountInformation

  const filteredCoupons = isWebsiteValid
    ? orderCouponCodes.filter(coupon => coupon.code !== 'urldbdesignfee')
    : orderCouponCodes

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
    trigger,
    clearErrors,
  } = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      mobile,
      isUpgrade: false,
      CCOnboarding: false,
      isKickoffNotRequired: isKickoffNotRequiredCC || false,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(SCHEMA),
    context: {
      componentProps: {
        onboardingConfirmed,
        creativeConfirmed,
        selectedCountry: selectedCountry.isoValue,
      },
    },
  })
  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12
  const isCurrentPlanSync = currentPlan?.includes('sync')
  const isSelectedPlanSuperior = ['plus', 'professional', 'unlimited'].includes(
    selectedPlan?.name,
  )
  const isCurrentPlanDemo =
    currentPlan?.includes('demo') || currentPlan?.includes('freetrial')
  const isSyncUpgrade = isCurrentPlanSync && isSelectedPlanSuperior
  const isPurchaseIntentVisible =
    isNewOrder ||
    isSyncUpgrade ||
    isCurrentPlanDemo ||
    (isUpgrade && selectedCentersAndApps)
  const isOneTimeAddonSelected = Object.values(ADD_ON.one_time).some(
    ({label}) => selectedAddons?.some(({product}) => product?.includes(label)),
  )
  const isSocialBoostSelected = selectedAddons?.some(
    ({product}) => product === ADD_ON.entitlements['Social Boost'].label,
  )

  const is4MCSelected = selectedAddons?.some(({planCode}) =>
    ONBOARDING_REQUIRED_4PLAN_UPGRADES.includes(planCode),
  )

  const isMCAlreadyIncluded =
    !selectedCentersAndApps &&
    upgradeAccountInfo?.addOns.some(({addOnCode}) =>
      ONBOARDING_REQUIRED_4PLAN_UPGRADES.includes(addOnCode),
    )
  const isOnboardingRequired4Upgrade =
    isUpgrade && is4MCSelected && !isMCAlreadyIncluded

  const [csmExtraInfo, firstNameInput, lastNameInput, emailInput, mobileInput] =
    watch(['csmExtraInfo', 'firstName', 'lastName', 'email', 'mobile'])

  const handleOnChange = () => setUseAccountOwnerInfo(!useAccountOwnerInfo)

  const saveCallData = data => {
    if (data.callType === 'kickoff') {
      setValue('onboardingCallDate', data.longDate)
      setOnboardingCallDate(data)
    } else if (data.callType === 'creative') {
      setValue('creativeCallDate', data.longDate)
      setCreativeCallDate(data)
    }
  }

  const handleNextStep = useCallback(() => {
    const data = {
      onBoardingProgramData: {
        intents: [
          firstPurchaseIntent,
          secondPurchaseIntent,
          thirdPurchaseIntent,
        ],
        onboardingCallDate,
        creativeCallDate,
        csmExtraInfo,
        accountOwnerInfoUsed: useAccountOwnerInfo,
        accountInfo: {
          firstName: firstNameInput,
          lastName: lastNameInput,
          email: emailInput,
          mobile: mobileInput,
        },
      },
    }
    if (onboardingCallDate?.longDate && !kickoffApptId) {
      toast.warning('Please confirm Thryv Kickoff Call date.')
      return
    }
    nextStep(data)
  }, [
    creativeCallDate,
    csmExtraInfo,
    emailInput,
    firstNameInput,
    firstPurchaseIntent,
    lastNameInput,
    mobileInput,
    nextStep,
    onboardingCallDate,
    secondPurchaseIntent,
    thirdPurchaseIntent,
    useAccountOwnerInfo,
    kickoffApptId,
  ])

  const getDisabledButton = () => {
    // in case onboarding confirmed we are setting continue as true for tl mc changes
    console.log(onboardingCallDate, onboardingConfirmed)
    if (onboardingConfirmed) {
      return false
    }
    //
    if (isKickoffNotRequiredCC && !displayCreativeCallModal) return false
    // ========================================
    // logic added to make kickoff call mandatory
    let is4VSEOAddonsAdded = false
    if (selectedAddons) {
      let is4VNewSEOExist = selectedAddons.find(item => {
        return [
          'addon-4-seobase-m2m',
          'addon-4-seokey-m2m',
          'addon-4-seopb-m2m',
        ].includes(item?.planCode)
      })
      if (is4VNewSEOExist) {
        let isSEOAlreadyIncluded = upgradeAccountInfo.addOns.find(
          item => item.addOnCode === 'addon-4-seobase-m2m',
        )
        if (!isSEOAlreadyIncluded) {
          is4VSEOAddonsAdded = true
        }
      }
    }
    // tl mc changes
    if (isNewThryvLeads && isNewTLMCAccessible) {
      return true
    }
    //
    if (is4VSEOAddonsAdded) {
      return true
    }
    if (
      displayKickOffCall &&
      typeof continueDisabled === 'boolean' &&
      !continueDisabled
    ) {
      return true
    }
    // ==============================================
    return !isUpgrade ||
      (!isKickoffNotRequiredCC && selectedCentersAndApps) ||
      displayCreativeCallModal ||
      isOnboardingRequired4Upgrade
      ? !continueDisabled
      : false
  }
  useEffect(() => {
    setAllSelectedPurchaseIntent({
      firstPurchaseIntent:
        Object.values(firstPurchaseIntent).length > 0
          ? firstPurchaseIntent
          : allSelectedPurchaseIntent?.firstPurchaseIntent,
      secondPurchaseIntent:
        Object.values(secondPurchaseIntent).length > 0
          ? secondPurchaseIntent
          : allSelectedPurchaseIntent?.secondPurchaseIntent,
      thirdPurchaseIntent:
        Object.values(thirdPurchaseIntent).length > 0
          ? thirdPurchaseIntent
          : allSelectedPurchaseIntent?.thirdPurchaseIntent,
    })

    setFirstPurchaseIntent(
      Object.values(firstPurchaseIntent).length > 0
        ? firstPurchaseIntent
        : allSelectedPurchaseIntent?.firstPurchaseIntent,
    )
    setSecondPurchaseIntent(
      Object.values(secondPurchaseIntent).length > 0
        ? secondPurchaseIntent
        : allSelectedPurchaseIntent?.secondPurchaseIntent,
    )
    setThirdPurchaseIntent(
      Object.values(thirdPurchaseIntent).length > 0
        ? thirdPurchaseIntent
        : allSelectedPurchaseIntent?.thirdPurchaseIntent,
    )
  }, [
    allSelectedPurchaseIntent?.firstPurchaseIntent,
    allSelectedPurchaseIntent?.secondPurchaseIntent,
    allSelectedPurchaseIntent?.thirdPurchaseIntent,
    firstPurchaseIntent,
    secondPurchaseIntent,
    thirdPurchaseIntent,
    setAllSelectedPurchaseIntent,
  ])

  useEffect(() => {
    // tl mc logic
    if (selectedAddons && isNewTLMCAccessible) {
      let isThryvLeadsExist = selectedAddons.find(
        item =>
          item?.addOnCode?.includes('tl-') ||
          (item?.planCode?.includes('tl-') &&
            item.tempLineActionCode === 'New'),
      )
      if (isThryvLeadsExist) {
        setNewThryvLead(true)
        setContinueDisabled(true)
      }
    }
    // tl mc logic
  }, [])

  useEffect(() => {
    if (isKickoffNotRequiredCC && !isSyncUpgrade) {
      setDisplayKickOffCall(false)
    }
  }, [selectedCentersAndApps, isSyncUpgrade, isUpgrade, isKickoffNotRequiredCC])

  useEffect(() => {
    setValue('firstPurchaseIntent', firstPurchaseIntent)
  }, [firstPurchaseIntent, setValue])

  useEffect(() => {
    setValue('secondPurchaseIntent', secondPurchaseIntent)
  }, [secondPurchaseIntent, setValue])

  useEffect(() => {
    setValue('thirdPurchaseIntent', thirdPurchaseIntent)
  }, [thirdPurchaseIntent, setValue])

  useEffect(() => {
    if (
      (selectedIndustryId === 13 || !displayKickOffCall) &&
      displayCreativeCallModal
    ) {
      setContinueDisabled(creativeApptId || isApptSkipped.creative)
    } else if (displayCreativeCallModal && isUpgrade && !onboardingCallDate) {
      setContinueDisabled(creativeApptId || isApptSkipped.creative)
    } else if (displayCreativeCallModal) {
      setContinueDisabled(
        (creativeApptId || isApptSkipped.creative) &&
          (kickoffApptId || isApptSkipped.kickoff),
      )
    } else {
      setContinueDisabled(kickoffApptId || isApptSkipped.kickoff)
    }
  }, [
    displayCreativeCallModal,
    kickoffApptId,
    creativeApptId,
    selectedIndustryId,
    displayKickOffCall,
    creativeCallDate,
    isUpgrade,
    onboardingCallDate,
    isApptSkipped.creative,
    isApptSkipped.kickoff,
    selectedAddons, // extra param added for tl mc logic
    isNewThryvLeads, // extra param added for tl mc logic
  ])

  useEffect(() => {
    if (useAccountOwnerInfo) {
      setValue('firstName', firstName)
      setValue('lastName', lastName)
      setValue('email', email)
      setValue('mobile', mobile)
    } else {
      setValue('firstName', '')
      setValue('lastName', '')
      setValue('email', '')
      setValue('mobile', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useAccountOwnerInfo])

  useEffect(() => {
    if (isNewOrder || isSyncUpgrade || isOnboardingRequired4Upgrade) {
      if (isKickoffNotRequiredCC) {
        setValue('isUpgrade', true)
      } else {
        setValue('isUpgrade', false)
      }
    } else {
      setValue('isUpgrade', true)
    }
  }, [
    pathname,
    setValue,
    isNewOrder,
    isSyncUpgrade,
    selectedCentersAndApps,
    isKickoffNotRequiredCC,
    isOnboardingRequired4Upgrade,
  ])

  useEffect(() => {
    if (
      (isWebsiteIncluded || isSocialBoostIncluded) &&
      !isOneTimeAddonSelected &&
      !isSocialBoostSelected
    ) {
      setDisplayCreativeCallModal(false)
      setValue('showCreativeCall', false)
    }
  }, [
    isOneTimeAddonSelected,
    isSocialBoostIncluded,
    isSocialBoostSelected,
    isUpgrade,
    isWebsiteIncluded,
    setValue,
  ])
  useEffect(() => {
    if (selectedCentersAndApps) {
      setValue('CCOnboarding', true)
    } else {
      setValue('CCOnboarding', false)
    }
  }, [selectedCentersAndApps, setValue])

  useEffect(() => {
    if (
      selectedCentersAndApps &&
      !displayKickOffCall &&
      !displayCreativeCallModal
    ) {
      handleNextStep()
    }
  }, [
    displayCreativeCallModal,
    displayKickOffCall,
    selectedCentersAndApps,
    nextStep,
    handleNextStep,
  ])

  return (
    <>
      <NewOrderLayout
        prevStep={prevStep}
        showContinueButton={true}
        showCancelButton={true}
        disableNextButton={getDisabledButton()}
        nextStep={handleSubmit(handleNextStep)}
        showModal={onboardingConfirmed || creativeConfirmed}
        kickoffApptId={kickoffApptId}
        creativeApptId={creativeApptId}
        countryISOValue={selectedCountry.isoValue}
        setCreativeConfirmed={setCreativeConfirmed}
        setOnboardingConfirmed={setOnboardingConfirmed}
        setOnboardingCallDate={setOnboardingCallDate}
        setCreativeCallDate={setCreativeCallDate}
        order5NUId={order5NUId}
        isCancelOrder5={isCancelOrder5}
        cancelOrder5={cancelOrder5}
        captureOrderNewUpgrade={captureOrderNewUpgrade}
        order5OnboardingApptId={order5OnboardingApptId}
        order5CreativeApptId={order5CreativeApptId}
        setOrder5CreativeApptId={setOrder5CreativeApptId}
        setOrder5OnboardingApptId={setOrder5OnboardingApptId}
      >
        {selectedCentersAndApps && (
          <div>
            <CcSidebar
              selectedCentersAndApps={selectedCentersAndApps}
              incentiveCoupon={incentiveCoupon}
              country={selectedCountry.isoValue}
              orderCouponCodes={filteredCoupons}
              eligibleTransitionCenters={eligibleTransitionCenters}
              defaultCentersAndApps={defaultCentersAndApps}
              previewRequestFinished={previewRequestFinished}
              upgradePaymentPreview={upgradePaymentPreview}
              isCohort={isCohort}
              accountInfo={{
                ...accountInformation,
                balanceInfo: upgradeAccountInfo?.balanceInfo || {},
              }}
              isWebsiteValid={isWebsiteValid}
              isNewTLMCAccessible={isNewTLMCAccessible}
            />
          </div>
        )}
        {!selectedCentersAndApps && (
          <div>
            <CartPaymentDetails
              listOfAddons={selectedAddons}
              selectedCountry={selectedCountry.value}
              currentStep={currentStep}
              setCartProducts={setCartProducts}
              cartProducts={cartProducts}
              totalDiscount={0}
              currentPlanName={currentPlanName}
              upgradeAccountInfo={upgradeAccountInfo}
            />
          </div>
        )}
        <OnboardingContainer
          style={
            selectedCentersAndApps
              ? {
                  borderLeft: '1px solid #CCCCCC',
                  gridColumnStart: 2,
                  alignContent: 'start',
                }
              : {}
          }
        >
          <div
            className={
              !selectedCentersAndApps ? 'pl-4 pr-4 pt-4' : 'pl-4 pb-4 pr-4'
            }
          >
            <ParagraphText variant="lg" className="font-weight-bold">
              {selectedCentersAndApps
                ? 'Onboarding Contact'
                : 'ONBOARDING CONTACT'}
            </ParagraphText>
            <InputContainer
              className={!selectedCentersAndApps ? 'mb-5' : 'mb-2'}
            >
              <div className="mb-2">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="First Name"
                  aria-label="First Name"
                  name="firstName"
                  withLabel
                  labelType="floating"
                  variant="default"
                  disabled={useAccountOwnerInfo}
                  register={register}
                  errors={errors}
                />
              </div>
              <Input
                className="w-100"
                type="text"
                placeholder="Last Name"
                aria-label="Last Name"
                name="lastName"
                withLabel
                labelType="floating"
                variant="default"
                disabled={useAccountOwnerInfo}
                register={register}
                errors={errors}
              />
              <div className="mb-2">
                <Input
                  className="w-100"
                  type="text"
                  placeholder="Email Address"
                  aria-label="Email Address"
                  name="email"
                  withLabel
                  labelType="floating"
                  variant="default"
                  disabled={useAccountOwnerInfo}
                  register={register}
                  errors={errors}
                />
              </div>
              <Input
                className="w-100"
                type="text"
                placeholder="Phone Number"
                aria-label="Phone Number"
                name="mobile"
                withLabel
                labelType="floating"
                variant="default"
                disabled={useAccountOwnerInfo}
                register={register}
                errors={errors}
              />
              <Checkbox
                hasLabel
                name="HTML"
                isChecked={useAccountOwnerInfo}
                onChange={handleOnChange}
                label="Same as account owner"
              />
            </InputContainer>
            {isPurchaseIntentVisible && !selectedCentersAndApps && (
              <>
                <ParagraphText variant="lg" className="font-weight-bold">
                  PURCHASE INTENT
                </ParagraphText>

                <ParagraphText variant="reg" color="thryv-gray-medium-500">
                  What features is client most interested in?
                </ParagraphText>
                <PurchaseIntentGroupContainer>
                  <PurchaseIntentModal
                    parentProps={{
                      title: 'First Purchase Intent',
                      purchaseIntent: firstPurchaseIntent,
                      errors: {errors},
                      name: 'firstPurchaseIntent',
                    }}
                    modalProps={{
                      setSelectedPurchaseIntent: setFirstPurchaseIntent,
                      selectedPurchaseIntents: [
                        secondPurchaseIntent.option,
                        thirdPurchaseIntent.option,
                      ],
                      selectedPurchaseIntent: firstPurchaseIntent,
                    }}
                  />
                  <PurchaseIntentModal
                    parentProps={{
                      title: 'Second Purchase Intent',
                      purchaseIntent: secondPurchaseIntent,
                      errors: {errors},
                      name: 'secondPurchaseIntent',
                    }}
                    modalProps={{
                      setSelectedPurchaseIntent: setSecondPurchaseIntent,
                      selectedPurchaseIntents: [
                        firstPurchaseIntent.option,
                        thirdPurchaseIntent.option,
                      ],
                      selectedPurchaseIntent: secondPurchaseIntent,
                    }}
                  />
                  <PurchaseIntentModal
                    parentProps={{
                      title: 'Third Purchase Intent',
                      purchaseIntent: thirdPurchaseIntent,
                      errors: {errors},
                      name: 'thirdPurchaseIntent',
                    }}
                    modalProps={{
                      setSelectedPurchaseIntent: setThirdPurchaseIntent,
                      selectedPurchaseIntents: [
                        firstPurchaseIntent.option,
                        secondPurchaseIntent.option,
                      ],
                      selectedPurchaseIntent: thirdPurchaseIntent,
                    }}
                  />
                </PurchaseIntentGroupContainer>
              </>
            )}
          </div>
          {isPurchaseIntentVisible && !isKickoffNotRequiredCC && (
            <div
              className={
                !selectedCentersAndApps ? 'pb-4 pl-4 pr-4' : 'pt-4 pr-4 pl-4'
              }
            >
              <ParagraphText
                variant={!selectedCentersAndApps ? 'reg' : 'lg'}
                color={!selectedCentersAndApps && 'thryv-gray-medium-500'}
              >
                Is there anything else you want the CSM to know about the
                business and why they are purchasing Thryv?
              </ParagraphText>
              <TextAreaContainer>
                <TextArea
                  type="text"
                  name="csmExtraInfo"
                  limit={750}
                  register={register}
                  errors={errors}
                  height={'10px'}
                />
              </TextAreaContainer>
            </div>
          )}
          <BookAppointments
            selectedCentersAndApps={selectedCentersAndApps}
            saveCallData={saveCallData}
            setDisplayCreativeCallModal={setDisplayCreativeCallModal}
            setDisplayKickOffCall={setDisplayKickOffCall}
            setOnboardingConfirmed={setOnboardingConfirmed}
            setCreativeConfirmed={setCreativeConfirmed}
            setIsApptSkipped={setIsApptSkipped}
            selectedCountry={selectedCountry}
            selectedAddons={selectedAddons}
            selectedIndustryId={selectedIndustryId}
            onboardingCallDate={onboardingCallDate}
            useAccountOwnerInfo={useAccountOwnerInfo}
            accountInformation={accountInformation}
            upgradeAccountInfo={upgradeAccountInfo}
            isApptSkipped={isApptSkipped}
            setKickoffApptId={setKickoffApptId}
            kickoffApptId={kickoffApptId}
            setCreativeApptId={setCreativeApptId}
            creativeApptId={creativeApptId}
            setOnboardingCallDate={setOnboardingCallDate}
            creativeCallDate={creativeCallDate}
            setCreativeCallDate={setCreativeCallDate}
            onboardingConfirmed={onboardingConfirmed}
            creativeConfirmed={creativeConfirmed}
            displayKickOffCall={displayKickOffCall}
            displayCreativeCallModal={displayCreativeCallModal}
            isSyncUpgrade={isSyncUpgrade}
            industryCategoryType={industryCategoryType}
            isKickoffNotRequiredCC={isKickoffNotRequiredCC}
            defaultCentersAndApps={defaultCentersAndApps}
            order5NUId={order5NUId}
            captureOrderNewUpgrade={captureOrderNewUpgrade}
            setOrder5CreativeApptId={setOrder5CreativeApptId}
            setOrder5OnboardingApptId={setOrder5OnboardingApptId}
            input={{
              firstNameInput,
              lastNameInput,
              emailInput,
              mobileInput,
            }}
            purchaseIntent={{
              firstPurchaseIntent,
              secondPurchaseIntent,
              thirdPurchaseIntent,
            }}
            useFormData={{
              register,
              errors,
              trigger,
              clearErrors,
              setValue,
            }}
            isNewTLMCAccessible={isNewTLMCAccessible}
            keapAccount={keapAccount}
          />
        </OnboardingContainer>
      </NewOrderLayout>
    </>
  )
}

export {OnboardingProgram}
