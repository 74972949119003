import React, {useEffect, useState} from 'react'

import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import {ADD_ON} from '../utils/addons-data'
import {Entitlements} from '../components/add-on-components/entitlements'
import {AddOns} from '../components/add-on-components/addons'
import SkeletonLoader from '../components/skeleton-loader'
import {CartAddons} from '../components/cart-addons'
import useDebounce from '../utils/useDebounce'

import {OneTime} from '../components/add-on-components/one-time'
import {NewOrderLayout} from '../components/new-order-layout'
import {useSearchParams} from 'react-router-dom'
import {useLocation} from 'react-router'
import {AccountInfo} from '../components/account-info'
import {toast} from 'react-toastify'

import {CURRENCYS, ADDONS_TYPES} from '../utils/constants'

const HIPAA_COMPLIANT_INDUSTRIES = [
  '52k10f2ml8m1f41u', // dentists
  'pcqjcmfqjvknm31t', // health & wellness
  'e8cgl2m7jp221sx7', // healthcare providers
]

const WEBSITE_ADDON_CODES = [
  'kv1x1fpgv24m',
  'kvtsoc832kz6',
  'kvmt5jfd32b7',
  'kvtt6yf7o5p7',
  'kv1upcfi0yrr',
]

const NO_WEBSITE_ENT_PLANS = [
  'sync-4-dexyp-m2m',
  'plus-4-dexyp-m2m',
  'addon-4-mc-m2m',
]

const MC_PLAN_CODES = [
  ...ADD_ON.add_ons['Marketing Center Plus'].addOnCodes,
  ...ADD_ON.add_ons['Marketing Center Pro'].addOnCodes,
  ...ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes,
]

const WIN_BACK_4_COUPONS = {
  'plus-4-dexyp-m2m': 'pluswinback2022',
  'unlimited-4-dexyp-m2m': 'unlimitedwinback2022',
  'professional-4-dexyp-m2m': 'professionalwinback2022',
}

const UpgradeAddonsSelection = ({
  prevStep,
  nextStep,
  selectedPlan,
  billingPreference,
  selectedCountry,
  industryCategoryType,
  selectedIndustry,
  accountInfo,
  accountSubscriptionData,
  isWebsiteIncluded,
  currentGmailItems,
  all5PlanPrices,
  isTSS,
  isEaidFound,
  cohortEaid,
  setIsEaidFound,
  setCohortEaid,
  setAllowInvoice,
  setIsCohort,
  captureOrder4NewUpgrade,
  orderData4CU,
  order5NUId,
  isCancelOrder5,
  cancelOrder5,
  isNewTLMCAccessible,
}) => {
  const {pathname} = useLocation()
  const isUpgrade = pathname === '/order/upgrade'
  const [listOfProducts, setListOfProducts] = useState(
    selectedPlan.industryType === 'thryv' &&
      accountInfo.planCode.includes('sync') &&
      !accountInfo.hasPaidOnboardingFee
      ? [
          {
            type: 'selectedPlan',
            product: selectedPlan.planName,
            price: `${selectedPlan.amount}`,
            billingPreference: billingPreference.value,
            planCode: selectedPlan.planCode,
          },
          {
            type: 'selectedPlan',
            product: 'Onboarding Fee',
            price: `$${parseFloat(selectedPlan.onboarding).toFixed(2)}`,
            planCode: 'onboarding-fee',
            id: 'onboarding-fee',
          },
        ]
      : [
          {
            type: 'selectedPlan',
            product: selectedPlan.planName,
            price: `${parseFloat(selectedPlan.amount).toFixed(2)}`,
            billingPreference: billingPreference.value,
            planCode: selectedPlan.planCode,
          },
        ],
  )
  console.log('listOfProducts - on init', listOfProducts)
  const [listOfAddons, setListOfAddons] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [gmailPackageConfiguration, setGmailPackageConfiguration] = useState({
    totalSeats: 0,
    availableSeats: 0,
  })
  //const [isLegacyUpgradeAllowed, setIsLegacyUpgradeAllowed] = useState(false)

  const isPlusPackage = listOfProducts.some(
    x => x.product === 'Plus (6 mo. Term)' || x.product === 'Plus',
  )
  const isProfessionalPackage = listOfProducts.some(
    x =>
      x.product === 'Professional (6 mo. Term)' ||
      x.product === 'Professional' ||
      x.product === 'Pro (12 mo. Term)',
  )
  const isUnlimitedPackage = listOfProducts.some(
    x => x.product === 'Unlimited (6 mo. Term)' || x.product === 'Unlimited',
  )
  const isSync = listOfProducts.some(
    x => x.product === 'Sync (6 mo. Term)' || x.product === 'Sync',
  )
  const isThryvLeads = listOfProducts.some(x => x.product === 'Thryv Leads')
  const isMarketingCenter = listOfProducts.some(x =>
    ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(x.planCode),
  )
  const hasMC = accountInfo?.addOns?.find(
    addon =>
      ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
        addon.addOnCode,
      ) ||
      ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(
        addon.addOnCode,
      ),
  )
  const hasSEO = accountInfo?.addOns?.find(addon => addon.name.includes('SEO'))
  const [mcDomainConfig, setMcDomainConfig] = useState({
    isValid: hasMC ? true : false,
    value: '',
    shouldValidate: false,
    hideDomain: hasMC ? true : false,
  })
  const [seoDomainConfig, setSeoDomainConfig] = useState({
    isValid: hasSEO ? true : false,
    value: '',
    shouldValidate: false,
    hideDomain: hasSEO ? true : false,
  })
  const [shouldIncludeCSNAddon, setShouldIncludeCSNAddon] = useState(false)
  const [cSNAddonInfo, setCSNAddonInfo] = useState()
  const [orderTotals, setOrderTotals] = useState()
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  const [searchParams] = useSearchParams()

  const showaddons = searchParams.get('showaddons')
  const SHOW_HIPAA_ADDON =
    (selectedCountry === 'usa' &&
      HIPAA_COMPLIANT_INDUSTRIES.includes(selectedIndustry)) ||
    accountInfo?.addOns?.some(({addOnCode}) =>
      ['addon-4-hipaa-m2m', 'addon-4-hipaa-semi'].includes(addOnCode),
    )

  const SHOW_SEO_MBG_ADDON =
    listOfProducts.find(product => WEBSITE_ADDON_CODES.includes(product.id)) ||
    accountInfo.isLegacySEO

  const isSEOInCart = listOfProducts.find(
    product =>
      product.product === 'SEO Non-Guaranteed' ||
      product.product === 'SEO Money Back Guarantee',
  )

  const websiteEntWithMCProPlans = NO_WEBSITE_ENT_PLANS.includes(
    selectedPlan.planCode,
  )
  const hasCSN = accountInfo.addOns.some(
    x => x.name === ADD_ON.add_ons['Thryv CSN'].label || x.name === 'Thryv ESS',
  )
  const upgradeToUnlimited = selectedPlan?.planName.includes('Unlimited')
  const isSupportFeeIncluded = accountInfo.addOns.some(
    ({addOnCode}) => addOnCode === '247support-m2m',
  )
  const isSyncPlan = selectedPlan.planCode.includes('sync')
  const isMCIncluded = accountInfo.addOns.some(({addOnCode}) =>
    MC_PLAN_CODES.includes(addOnCode),
  )
  const isMCProEnhancedInCart = listOfProducts.some(({planCode}) =>
    ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
      planCode,
    ),
  )

  const filterCSN = (listOfAddons, name) => {
    return hasCSN
      ? listOfAddons
      : listOfAddons.filter(({name: addonName}) => addonName !== name)
  }

  const showConditionalAddons = () => {
    const filteredAddons = filterCSN(
      listOfAddons.addOns,
      ADD_ON.add_ons['Thryv CSN'].label,
    ).filter(addon => {
      return addon[billingPreference.value]
    })

    if (listOfAddons.addOns) {
      if (SHOW_HIPAA_ADDON && SHOW_SEO_MBG_ADDON) {
        return filteredAddons
      } else if (SHOW_HIPAA_ADDON && !SHOW_SEO_MBG_ADDON) {
        return filteredAddons.filter(
          ({name}) => name !== ADD_ON.add_ons['SEO Money Back Guarantee'].label,
        )
      } else if (SHOW_SEO_MBG_ADDON) {
        return filteredAddons.filter(
          ({name}) => name !== ADD_ON.add_ons['HIPAA'].label,
        )
      }
      return filteredAddons.filter(
        ({name}) =>
          name !== ADD_ON.add_ons['SEO Money Back Guarantee'].label &&
          name !== ADD_ON.add_ons['HIPAA'].label,
      )
    }
  }

  const updateAvailableGmailSeats = value => {
    const {availableSeats} = gmailPackageConfiguration
    if (availableSeats >= 0) {
      if (availableSeats - value < 0) {
        return
      }
      setGmailPackageConfiguration(prev => ({
        ...prev,
        availableSeats: prev.availableSeats - value,
      }))
    }
  }

  const setSelectedCSNAddonInfo = () => {
    if (cSNAddonInfo) return
    const cSNAddon = listOfProducts.find(
      ({product}) =>
        product === ADD_ON.add_ons['Thryv CSN'].label ||
        product === 'Thryv ESS',
    )
    if (cSNAddon) {
      setCSNAddonInfo([
        {
          ...cSNAddon,
          price: cSNAddon.price.replace(/[$,a-zA-Z]/g, ''),
        },
      ])
    }
  }

  const addProduct = productList => {
    //=== tl mc changes added
    if (productList.length) {
      let isNewTLIncluded = productList.find(item =>
        item?.planCode.includes('tl-mc'),
      )
      if (isNewTLIncluded) {
        if (isNewTLMCAccessible) {
          let isGrowthPackageInclude = listOfProducts.find(item =>
            item.product.includes('Growth Package'),
          )
          if (isGrowthPackageInclude) {
            removeProduct([isGrowthPackageInclude?.id])
          }
        } /* else {
          productList = productList.map(item => {
            if (item?.planCode.includes('tl-mc')) {
              item.planCode = item.planCode.replace('tl-mc', 'tl')
            }
            return item
          })
        } */
      }
    }
    // ===
    setListOfProducts(prev => [...prev, ...productList])
  }

  const updateProduct = (updatedProduct, compareFieldName) => {
    const callbackFn = prev => {
      return prev.map(product => {
        if (compareFieldName) {
          if (
            product.type === updatedProduct.type &&
            product[compareFieldName] === updatedProduct[compareFieldName]
          ) {
            return {...product, ...updatedProduct}
          }
        }
        if (product.id === updatedProduct.id) {
          return {...product, ...updatedProduct}
        } else if (
          product?.planCode?.includes('4-seobase-') &&
          updatedProduct?.planCode?.includes('4-seobase-')
        ) {
          return {
            ...product,
            proposalId: updatedProduct.proposalId,
            domain: updatedProduct.domain,
            price: updatedProduct.price,
            quantity: updatedProduct.quantity,
          }
        }

        return product
      })
    }
    setListOfProducts(callbackFn)
  }

  const removeProduct = (productsId, customFilter) => {
    const isMCProduct = listOfProducts.find(
      item =>
        item.product.includes('Marketing Center') && item.id === productsId,
    )
    let newSEOIds = []
    if (isMCProduct) {
      listOfProducts.map(item => {
        if (
          [
            'addon-4-seokey-m2m',
            'addon-4-seopb-m2m',
            'addon-4-seobase-m2m',
          ].includes(item.planCode)
        ) {
          newSEOIds.push(item.id)
        }
        return item
      })
    }
    if (!Array.isArray(productsId)) productsId = [productsId]
    if (newSEOIds.length > 0) {
      productsId = [...productsId, ...newSEOIds]
    }
    let filter
    if (customFilter) {
      filter = customFilter
    } else {
      filter = ({id}) => !productsId.includes(id)
    }
    setListOfProducts(prev => prev.filter(filter))
  }

  const debouncedOrderTotals = useDebounce(orderTotals, 500)

  useEffect(() => {
    const isMCIncluded = accountInfo.addOns.some(({addOnCode}) =>
      MC_PLAN_CODES.includes(addOnCode),
    )
    const supportFeeInCart = listOfProducts.find(
      ({planCode}) => planCode === '247support-m2m',
    )
    const subtotal = +orderTotals
      ?.find(({product}) => product === 'Subtotal')
      ?.price?.replace('$', '')
      .replace(',', '')

    if (isTSS && (isMCIncluded || !isSyncPlan) && !isSupportFeeIncluded) {
      if (supportFeeInCart && accountInfo.subtotal >= subtotal - 9) {
        removeProduct([supportFeeInCart.id])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOrderTotals])

  useEffect(() => {
    if (!isSyncPlan) return

    const isMCInCart = listOfProducts.some(({planCode}) =>
      MC_PLAN_CODES.includes(planCode),
    )
    const isOnboardingFeeInCart = listOfProducts.some(
      ({planCode}) => planCode === 'onboarding-fee',
    )
    const onboardingFee = {
      type: 'selectedPlan',
      product: 'Onboarding Fee',
      price: `$${parseFloat(selectedPlan.onboarding).toFixed(2)}`,
      planCode: 'onboarding-fee',
      id: 'onboarding-fee',
    }

    if (
      isMCInCart &&
      !isMCIncluded &&
      !accountInfo.hasPaidOnboardingFee &&
      !isOnboardingFeeInCart
    ) {
      addProduct([onboardingFee])
    }

    if (!isMCInCart && !isMCIncluded && isOnboardingFeeInCart) {
      removeProduct([onboardingFee.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOrderTotals])

  const validateRequiredAddons = async () => {
    if (
      selectedPlan.planName === 'Thryv Leads' &&
      !listOfProducts.some(
        product =>
          product.product === 'Thryv Leads' && product.type === 'addon',
      )
    ) {
      toast.error('This plan requires Thryv Leads add-on.')
      return
    }
    let is24x7FeeRequired = false
    let filteredListOfProducts = [...listOfProducts]
    const winBackCoupon = WIN_BACK_4_COUPONS[selectedPlan.planCode]
    const isSupportFeeInCart = listOfProducts.some(
      ({planCode}) => planCode === '247support-m2m',
    )
    if (
      isUpgrade &&
      accountInfo.status !== 'active' &&
      winBackCoupon !== undefined
    ) {
      is24x7FeeRequired = false
    } else if (isSyncPlan) {
      is24x7FeeRequired = false
    } else {
      if (isSupportFeeInCart) {
        is24x7FeeRequired = true
      }
    }

    if (accountInfo.planCode !== selectedPlan.planCode) {
      is24x7FeeRequired = false
    }
    if (is24x7FeeRequired) {
      if (!isSupportFeeInCart) {
        const {centers} = all5PlanPrices
        const supportAndServiceFee = centers.find(
          ({code}) => code === '247support-m2m',
        )
        const supportBillingPref = 'monthly'
        const {unitAmount} = supportAndServiceFee.currencies.find(
          ({currency}) => currency === CURRENCYS[selectedCountry],
        )
        const supportFee = {
          id: supportAndServiceFee.id,
          billingPreference: supportBillingPref,
          planCode: supportAndServiceFee.code,
          price: `$${unitAmount.toFixed(2).toString()}`,
          product: supportAndServiceFee.name,
          quantity: 1,
          type: ADDONS_TYPES['ADD_ON'],
        }
        filteredListOfProducts.push(supportFee)
      }
    } else {
      filteredListOfProducts = listOfProducts.filter(
        product => product.planCode !== '247support-m2m',
      )
    }
    console.log('filteredListOfProducts', filteredListOfProducts)

    const selectedLines = getOrderLines4(listOfProducts)
    captureOrder4NewUpgrade(
      'addons-selection-continue',
      'upgrade',
      selectedLines,
    )
    nextStep({
      selectedAddons: filteredListOfProducts,
    })
  }

  const getOrderLines4 = listOfProducts => {
    console.log('listOfProducts', listOfProducts)
    console.log(
      'orderData4CU.accountInfo.addOns',
      orderData4CU.accountInfo.addOns,
    )
    const updatedAllLines = getAllSelectedDefaultLines(
      listOfProducts,
      orderData4CU.accountInfo.addOns,
    )
    let selectedLines = []
    let line_counter = 1
    for (let product of updatedAllLines) {
      let unitAmount = 0
      if (product.price) {
        if (product.price.indexOf('$') >= 0) {
          unitAmount = Number(product.price.split('$')[1].replaceAll(',', ''))
        } else {
          unitAmount = product.price
        }
      }
      const pUAmount =
        product.unitAmount || product.unitAmount === 0
          ? product.unitAmount
          : unitAmount || unitAmount === 0
          ? unitAmount
          : null
      let pUPrice = pUAmount
      if (pUAmount) {
        if (
          product.planCode === 'addon-4-seobase-m2m' ||
          product.planCode === 'addon-4-seokey-m2m' ||
          product.planCode === 'addon-4-seopb-m2m'
        ) {
          pUPrice = pUAmount * 1
        } else {
          pUPrice = pUAmount * (product.quantity ? product.quantity : 1)
        }
      }
      // let oneTimeAmount = null
      // if (product.type === 'oneTime') {
      //   let oUPrice =
      //     product.unitAmount || product.unitAmount === 0
      //       ? product.unitAmount
      //       : unitAmount || unitAmount === 0
      //       ? unitAmount
      //       : null
      //   if (oUPrice) {
      //     oUPrice = oUPrice * (product.quantity ? product.quantity : 1)
      //   }
      //   oneTimeAmount = oUPrice
      // }
      selectedLines.push({
        line_number: line_counter,
        parent_line_number: null,
        plansv5_id: product.id ? product.id : '',
        action_code: product.tempLineActionCode,
        order_line_status: 'InProgress',
        saas_product_category:
          product.type === 'entitlements'
            ? 'Center'
            : product.type === 'selectedPlan'
            ? 'Center'
            : product.type === 'oneTime'
            ? 'OneTime'
            : 'App',
        saas_product_type: product.type ? product.type : 'addon',
        // saas_product_type:
        //   product.type === 'selectedPlan'
        //     ? 'main'
        //     : product.type
        //     ? product.type
        //     : 'addon',
        saas_product_code: product.planCode
          ? product.planCode
          : product.addOnCode,
        saas_instance_id: null,
        recurly_subscription_id: product.uuid,
        saas_product_price: pUPrice,
        // saas_product_unit_amount: pUAmount,
        quantity: product.quantity ? product.quantity : 1,
        // one_time_fee: oneTimeAmount,
        one_time_fee: null,
        start_date: new Date(),
        end_date: null,
      })
      line_counter = line_counter + 1
    }
    console.log('selectedLines ', selectedLines)
    return selectedLines
  }

  const getAllSelectedDefaultLines = (lines1, lines2) => {
    const sLines = [...lines1]
    const dLines = [...lines2]
    // const tempOrderLines = []
    const selectedAndDefaultlines = []
    // const defaultLines = []

    dLines.forEach(dLine => {
      let found = false
      sLines.forEach(sLine => {
        if (dLine.addOnCode === sLine.planCode) {
          dLine.tempLineActionCode = 'Update'
          sLine.tempLineActionCode = 'Update'
          dLine.type = sLine.type
          found = true
        }
        // else {
        //   if (sLine.type === 'selectedPlan') {
        //     sLine.tempLineActionCode = 'Update'
        //     selectedAndDefaultlines.push(sLine)
        //   }
        // }
      })
      if (!found) {
        // if (dLine.type === 'selectedPlan') {
        //   dLine.tempLineActionCode = 'Update'
        // } else {
        dLine.tempLineActionCode = 'Cancel'
        // }
      }
      selectedAndDefaultlines.push(dLine)
    })

    // sLines.forEach(sLine => {
    //   let found = false
    //   dLines.forEach(dLine => {
    //     if (sLine.planCode === dLine.addOnCode) {
    //       sLine.tempLineActionCode = 'Update'
    //       found = true
    //       selectedAndDefaultlines.push(dLine)
    //     }
    //   })
    //   if (!found) {
    //     if (sLine.type === 'selectedPlan') {
    //       sLine.tempLineActionCode = 'Update'
    //     } else {
    //       sLine.tempLineActionCode = 'New'
    //     }
    //     selectedAndDefaultlines.push(sLine)
    //   }
    // })

    sLines.forEach(sLine => {
      const foundInArray2 = dLines.find(
        dLine => sLine.planCode === dLine.addOnCode,
      )
      // sLine.tempLineActionCode = foundInArray2 ? 'Update' : 'New'
      // if (!foundInArray2) selectedAndDefaultlines.push(sLine)
      if (!foundInArray2) {
        if (sLine.type === 'selectedPlan') {
          sLine.tempLineActionCode = 'Update'
        } else {
          sLine.tempLineActionCode = 'New'
        }
        selectedAndDefaultlines.push(sLine)
      }
    })

    console.log('selectedAndDefaultlines - ', selectedAndDefaultlines)
    // console.log('defaultLines - ', defaultLines)

    // tempOrderLines.push(...selectedlines, ...defaultLines)
    // console.log('tempOrderLines - ', tempOrderLines)

    return selectedAndDefaultlines
  }

  const getGmailSeats = () => {
    if (isPlusPackage) {
      return {
        availableSeats: 2,
        totalSeats: 2,
      }
    }
    if (isProfessionalPackage) {
      return {
        availableSeats: 5,
        totalSeats: 5,
      }
    }
    if (isSync) {
      return {
        availableSeats: 1,
        totalSeats: 1,
      }
    }
    if (isUnlimitedPackage) {
      return {
        availableSeats: 10,
        totalSeats: 10,
      }
    }
    if (isThryvLeads || isMarketingCenter) {
      return {
        availableSeats: 0,
        totalSeats: 0,
      }
    }
  }
  useEffect(() => {
    setGmailPackageConfiguration(getGmailSeats())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlan])
  useEffect(() => {
    if (shouldIncludeCSNAddon) {
      if (
        listOfProducts.some(
          ({product}) =>
            product === ADD_ON.add_ons['Thryv CSN'].label ||
            product === 'Thryv ESS',
        )
      )
        return
      addProduct(cSNAddonInfo)
      setShouldIncludeCSNAddon(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldIncludeCSNAddon, cSNAddonInfo, listOfProducts])
  useEffect(() => {
    const getAddonsPrices = async () => {
      let url
      if (showaddons) {
        url = `plans/list/all`
      } else {
        url = `plans/list/addons/${selectedPlan.name}`
      }
      const response = await run(client(url))
      setListOfAddons(response)
      setIsLoading(false)
    }

    getAddonsPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (listOfProducts.length > 1) {
      setSelectedCSNAddonInfo()
      if (
        accountInfo?.addOns.some(
          x =>
            x.name ===
              ADD_ON.add_ons['Google My Business Optimization'].label ||
            x.name.includes('GMB'),
        ) &&
        !listOfProducts.some(
          x =>
            x.product ===
            ADD_ON.add_ons['Google My Business Optimization'].label,
        )
      ) {
        const {addOnCode} = accountInfo.addOns.some(
          x =>
            x.name ===
              ADD_ON.add_ons['Google My Business Optimization'].label ||
            x.name.includes('GMB'),
        )
        if (listOfAddons.addOns) {
          const gmbCurrency = listOfAddons.addOns
            .find(
              x =>
                x.name ===
                ADD_ON.add_ons['Google My Business Optimization'].label,
            )
            [billingPreference.value][0].currencies.find(
              x => x.currency === CURRENCYS[selectedCountry],
            )
          const gmbPrice = `$${gmbCurrency.unitAmount
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
          const {id} = gmbCurrency
          const product = {
            id: id,
            billingPreference: billingPreference.value,
            planCode: addOnCode,
            price: gmbPrice,
            product: ADD_ON.add_ons['Google My Business Optimization'].label,
            quantity: 1,
            type: 'addon',
          }
          addProduct([product])
        }
      }
    }
  }, [listOfProducts]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (mcDomainConfig.isValid) {
      let mcAddon = listOfProducts.find(
        mc =>
          ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
            mc.planCode,
          ) ||
          ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(
            mc.planCode,
          ) ||
          ADD_ON.add_ons['Marketing Center Pro Enhanced'].addOnCodes.includes(
            mc.planCode,
          ),
      )
      mcAddon = {...mcAddon, sensisSiteDomain: mcDomainConfig.value}
      updateProduct(mcAddon)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mcDomainConfig])
  useEffect(() => {
    if (seoDomainConfig.isValid) {
      let seoAddon = listOfProducts.find(x => x.product.includes('SEO'))
      seoAddon = {...seoAddon, seoDomain: seoDomainConfig.value}
      updateProduct(seoAddon)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seoDomainConfig])

  useEffect(() => {
    if (isSEOInCart && !hasSEO) {
      if (!(seoDomainConfig.value && seoDomainConfig.isValid)) {
        setSeoDomainConfig(prev => ({
          ...prev,
          isValid: false,
          shouldValidate: true,
          hideDomain: false,
        }))
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfProducts])

  useEffect(() => {
    async function addWebsiteEnt() {
      const response = await client(`plans/list/all`)
      const websiteEnt = response.entitlements.find(
        ({code}) => code === 'ent-4-website-free',
      )
      const newEntitlements = [...listOfAddons.entitlements]
      newEntitlements.push(websiteEnt)
      setListOfAddons(prev => ({...prev, entitlements: newEntitlements}))
    }
    const isMCProInCart = listOfProducts.some(({planCode}) =>
      ADD_ON.add_ons['Marketing Center Pro'].addOnCodes.includes(planCode),
    )
    const isWebsiteInEntArray = listOfAddons.entitlements?.some(
      ({code}) => code === 'ent-4-website-free',
    )

    if (
      (isMCProInCart || isMCProEnhancedInCart) &&
      websiteEntWithMCProPlans &&
      !isWebsiteInEntArray
    ) {
      addWebsiteEnt()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfProducts, selectedPlan.planCode, websiteEntWithMCProPlans])
  useEffect(() => {
    if (listOfProducts.length === 0) return
    const websiteEnt = listOfProducts.find(
      ({planCode}) => planCode === 'ent-4-website-free',
    )
    const isMCProInCart = listOfProducts.some(
      ({planCode}) => planCode === 'addon-4-mcpro-m2m',
    )

    if (
      listOfAddons?.addOns &&
      websiteEntWithMCProPlans &&
      !isMCProInCart &&
      websiteEnt
    ) {
      removeProduct([websiteEnt?.id])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountInfo.addOns,
    listOfProducts,
    selectedPlan.planCode,
    listOfAddons?.addOns,
    websiteEntWithMCProPlans,
  ])

  const disableNext =
    (mcDomainConfig.shouldValidate && !mcDomainConfig.isValid) ||
    (seoDomainConfig.shouldValidate && !seoDomainConfig.isValid)
  // =================================================
  // temp fix logic provided
  let currentAddons = [...accountInfo.addOns, ...accountInfo.legacyAddOns]
  let isTLMCAvailable = currentAddons.find(item =>
    item?.addOnCode.includes('tl-'),
  )
  // ====================================================

  return (
    <NewOrderLayout
      prevStep={prevStep}
      title="Entitlements & Add-Ons"
      disableNextButton={disableNext}
      showContinueButton={true}
      showCancelButton={true}
      nextStep={validateRequiredAddons}
      cohortEaid
      setCohortEaid
      order5NUId={order5NUId}
      isCancelOrder5={isCancelOrder5}
      cancelOrder5={cancelOrder5}
    >
      <div>
        <div style={{paddingLeft: '5px'}}>
          <AccountInfo data={accountInfo} />
          <hr style={{width: '85%'}} />
        </div>

        <CartAddons
          listOfAddons={listOfProducts}
          selectedCountry={selectedCountry}
          accountInfo={accountInfo}
          setOrderTotals={setOrderTotals}
          billingPreference={billingPreference}
        />
      </div>
      <div>
        {isLoading ? (
          <SkeletonLoader width="90%" height="50vh" />
        ) : (
          <>
            <Entitlements
              data={listOfAddons.entitlements}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              currency={CURRENCYS[selectedCountry]}
              listOfProducts={listOfProducts}
              currentAddOns={[
                ...accountInfo.addOns,
                ...accountInfo.legacyAddOns,
              ]}
              isWebsiteIncluded={isWebsiteIncluded}
              availableGmailSeats={gmailPackageConfiguration.availableSeats}
              updateAvailableGmailSeats={updateAvailableGmailSeats}
              totalGmailSeats={gmailPackageConfiguration.totalSeats}
              currentGmailItems={currentGmailItems}
              selectedPlan={selectedPlan}
              selectedCountry={selectedCountry}
              unlimitedUpgrade={upgradeToUnlimited}
              listOfAddons={listOfAddons}
              setListOfAddons={setListOfAddons}
              currentPlan={accountInfo.planCode}
              planCreationDate={accountInfo.createdAt}
            />
            <AddOns
              data={showConditionalAddons()}
              accountInfo={accountInfo}
              accountSubscriptionData={accountSubscriptionData}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              selectedCountry={selectedCountry}
              currency={CURRENCYS[selectedCountry]}
              isUpgrade={true}
              industryCategoryType={industryCategoryType}
              listOfProducts={listOfProducts}
              currentAddOns={[
                ...accountInfo.addOns,
                ...accountInfo.legacyAddOns,
              ]}
              availableGmailSeats={gmailPackageConfiguration.availableSeats}
              updateAvailableGmailSeats={updateAvailableGmailSeats}
              totalGmailSeats={gmailPackageConfiguration.totalSeats}
              currentGmailItems={currentGmailItems}
              mcDomainConfig={mcDomainConfig}
              setMcDomainConfig={setMcDomainConfig}
              hasCSN={hasCSN}
              setShouldIncludeCSNAddon={setShouldIncludeCSNAddon}
              selectedPlan={selectedPlan}
              seoDomainConfig={seoDomainConfig}
              setSeoDomainConfig={setSeoDomainConfig}
              cohortEaid={cohortEaid}
              isEaidFound={isEaidFound}
              setAllowInvoice={setAllowInvoice}
              setCohortEaid={setCohortEaid}
              setIsEaidFound={setIsEaidFound}
              //setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
              thryvId={accountInfo?.thryvID}
              setIsCohort={setIsCohort}
              isLegacySEO={accountInfo.isLegacySEO}
              listOfAddons={listOfAddons}
              isNewTLMCAccessible={isNewTLMCAccessible}
              isThryvLeadsAvailable={isTLMCAvailable}
            />
            <OneTime
              data={listOfAddons.oneTimeAddOns}
              addProduct={addProduct}
              removeProduct={removeProduct}
              updateProduct={updateProduct}
              currency={CURRENCYS[selectedCountry]}
              listOfProducts={listOfProducts}
              currentAddOns={[
                ...accountInfo.addOns,
                ...accountInfo.legacyAddOns,
              ]}
              selectedCountry={selectedCountry}
              isNewTLMCAccessible={isNewTLMCAccessible}
            />
          </>
        )}
      </div>
    </NewOrderLayout>
  )
}

export {UpgradeAddonsSelection}
