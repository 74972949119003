import React from 'react'
import {Button, withModal} from '@thryvlabs/maverick'
import ToolItem from './tool-item'
import ThryvLeadsModal from './thryv-leads-modal'
import * as image from '../../assets/index'
import {ItemsContainer, ItemsContainerTitle} from '../ui/tools-page'
import {useNavigate} from 'react-router-dom'
import {getAuthByRole} from '../../utils/get-screen-auth-by-role'
import {useUserRoles} from '../../utils/use-user-roles'
import {useFlags} from 'launchdarkly-react-client-sdk'

const PROSPECTING_TOOLS_AUTHORIZED_ROLES = {
  packageRecommendation: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'yello',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvDemoRequest: ['admin', 'premise'],
  thryvPaySavingsProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'yello',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
  thryvLeads: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvCSN: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  seoMBGProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
  seoProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
}

const EDUCATIONAL_TOOLS_AUTHORIZED_ROLES = {
  thryvSpecialties: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'yello',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvLeadsJobAids: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvPayJobAids: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
  marketingCenterDemo: ['admin', 'leadconverter', 'premisepilot'],
  marketingCenterJobAids: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
}

const ToolsContainer = props => {
  const [toolDisplay, setToolDisplay] = React.useState({
    shown: false,
    title: '',
    url: '',
  })
  const [toolTitle, setToolTitle] = React.useState('')
  const [toolUrl, setToolUrl] = React.useState('')
  const {repId, repIdValue, create, tool, savetool, tRef} = props
  const {roles} = useUserRoles()
  const AUS_USER = roles.includes('australia')
  const navigate = useNavigate()
  const {frontendSideNav, frontendShowScans} = useFlags()

  const {REACT_APP_OOE_TOOLS_URL, REACT_APP_THRYV_PAY_SAVINGS_PROPOSAL_URL} =
    process.env

  const ToolItemModal = () => {
    const URL = `${REACT_APP_OOE_TOOLS_URL}/${toolUrl}`
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: '10',
        }}
      >
        <Button
          variant="primary"
          onClick={() =>
            setToolDisplay({
              shown: false,
              title: '',
              url: '',
            })
          }
          className="mt-3 mb-3 mr-3 float-right"
        >
          Close
        </Button>
        <iframe
          title={toolTitle}
          className="mt-3"
          id={toolUrl}
          src={URL}
          width="95%"
          height="85%"
          style={{
            display: 'block',
            margin: '0 auto',
          }}
        />
      </div>
    )
  }

  React.useEffect(() => {
    setToolTitle(toolDisplay.title)
    setToolUrl(toolDisplay.url)
  }, [toolDisplay])

  const ToolItemThryvLeads = ({showModal}) => (
    <ToolItem
      description="Thryv Leads"
      imageAlternative={image.thryvLeadsLogo}
      imageAlternativeName="Thryv Leads Logo"
      radioref={tRef}
      redirectUrl=""
      onClick={showModal}
    />
  )

  const ToolItemSalesPresentations = () => (
    <ToolItem
      description="Sales Presentations"
      imageAlternative={image.salesPresentationIcon}
      imageAlternativeName="Sales Presentations Logo"
      redirectUrl=""
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Sales Presentations',
          url: 'sales-presentations',
        })
      }
    />
  )

  const ToolItemThryvJobAids = () => (
    <ToolItem
      description="Thryv Job Aids"
      imageAlternative={image.thryvjobaids}
      imageAlternativeName="Thryv Job Aids Logo"
      onClick={() =>
        setToolDisplay(
          AUS_USER
            ? {
                shown: !toolDisplay.shown,
                title: 'Thryv Job Aids - Australia',
                url: 'thryv-job-aids-australia',
              }
            : {
                shown: !toolDisplay.shown,
                title: 'Thryv Job Aids',
                url: 'thryv-job-aids',
              },
        )
      }
    />
  )

  const ToolItemSalesBattleCards = () => (
    <ToolItem
      description="Sales Battle Cards"
      imageAlternative={image.salesBattleCardIcon}
      imageAlternativeName="Sales Battle Card Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Sales Battle Cards',
          url: 'sales-battle-cards',
        })
      }
    />
  )

  const ToolItemLeaveBehinds = () => (
    <ToolItem
      description="Leave Behinds"
      imageAlternative={image.leaveBehindsIcon}
      imageAlternativeName="Leave Behinds Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Leave Behinds',
          url: 'leave-behinds',
        })
      }
    />
  )

  const ToolItemMarketingCenterJobAids = () => (
    <ToolItem
      description="Thryv Marketing Center Job Aids"
      imageAlternative={image.marketingCenterJobAidsLogo}
      imageAlternativeName="Thryv Marketing Center Job Aids Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Marketing Center Job Aids',
          url: 'marketing-center-job-aids',
        })
      }
    />
  )

  const ToolItemBusinessCenterDemos = () => (
    <ToolItem
      description="Thryv Business Center Demos"
      imageAlternative={image.businessCenterDemoLogo}
      imageAlternativeName="Thryv Business Center Demos Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Business Center Demos',
          url: 'business-center-demos',
        })
      }
    />
  )

  const ToolItemCommandCenterDemos = () => (
    <ToolItem
      description="Thryv Command Center Demos"
      imageAlternative={image.commandCenterDemosIcon}
      imageAlternativeName="Thryv Command Center Demos Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Command Center Demos',
          url: 'command-center-demos',
        })
      }
    />
  )

  const ToolItemClientTestimonials = () => (
    <ToolItem
      description="Client Testimonials"
      imageAlternative={image.testimonial}
      imageAlternativeName="Client Testimonials Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Client Testimonials',
          url: 'testimonials',
        })
      }
    />
  )

  const ToolItemThryvPayDemos = () => (
    <ToolItem
      description="ThryvPay Demos"
      imageAlternative={image.thryvPayDemoLogo}
      imageAlternativeName="ThryvPay Demos Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Pay Demos',
          url: 'thryvpay-demos',
        })
      }
    />
  )

  const ToolItemThryvPayJobAids = () => (
    <ToolItem
      description="ThryvPay Job Aids"
      imageAlternative={image.thryvpayjobaids}
      imageAlternativeName="Thryv Pay Job Aids"
      onClick={() =>
        setToolDisplay(
          AUS_USER
            ? {
                shown: !toolDisplay.shown,
                title: 'Thryv Pay Job Aids - Australia',
                url: 'thryvpay-job-aids-australia',
              }
            : {
                shown: !toolDisplay.shown,
                title: 'Thryv Pay Job Aids',
                url: 'thryvpay-job-aids',
              },
        )
      }
    />
  )

  const ToolItemThryvLeadsJobAids = () => (
    <ToolItem
      description="Thryv Leads Job Aids"
      imageAlternative={image.jobleads}
      imageAlternativeName="Job Leads"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Leads Job Aids',
          url: 'thryv-leads-job-aids',
        })
      }
    />
  )

  const ToolItemMarketingCenterDemos = () => (
    <ToolItem
      description="Thryv Marketing Center Demos"
      imageAlternative={image.marketingCenterDemoLogo}
      imageAlternativeName="Thryv Marketing Center Demos Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Marketing Center Demos',
          url: 'marketing-center-demos',
        })
      }
    />
  )

  const ToolItemMarketplaceDemos = () => (
    <ToolItem
      description="Thryv Marketplace Demos"
      imageAlternative={image.marketplaceDemosIcon}
      imageAlternativeName="Thryv Marketplace Demos Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Marketplace Demos',
          url: 'marketplace-demos',
        })
      }
    />
  )

  const ToolItemThryvDemoRequest = () => (
    <ToolItem
      description="Thryv Demo Request"
      imageAlternative={image.thryvDemoRequestIcon}
      imageAlternativeName="Thryv Demo Request Logo"
      radioref={tRef}
      onClick={() => navigate('/new-demo-request')}
    />
  )

  const ToolItemSocialMediaGraphics = () => (
    <ToolItem
      description="Social Media Graphics"
      imageAlternative={image.socialMediaGraphicsIcon}
      imageAlternativeName="Social Media Graphics Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Social Media Graphics',
          url: 'social-media-graphics',
        })
      }
    />
  )

  const ToolItemLocalAds = () => (
    <ToolItem
      description="Local Ads"
      imageAlternative={image.localAdsIcon}
      imageAlternativeName="Local Ads Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Local Ads',
          url: 'local-ads',
        })
      }
    />
  )

  const ToolItemPromotionalOffers = () => (
    <ToolItem
      description="Thryv Promotional Offers"
      imageAlternative={image.ooePromotionalOffersIcon}
      imageAlternativeName="Thryv Promotional Offers"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Promotional Offers',
          url: 'promotional-offers',
        })
      }
    />
  )

  const ToolItemThryvLeadsWithModal = withModal(
    ToolItemThryvLeads,
    ThryvLeadsModal,
  )

  return (
    <div className="d-flex justify-content-center align-content-center">
      <div className={`${frontendSideNav && 'col-md-9 col-lg-8 mx-auto'}`}>
        {toolDisplay.shown ? <ToolItemModal /> : null}
        <ItemsContainerTitle
          fontWeight="medium"
          className="text-center"
          variant="h2"
          text="Prospecting Tools"
        >
          Prospecting Tools
        </ItemsContainerTitle>
        <ItemsContainer>
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.packageRecommendation,
            roles,
          ) && (
            <ToolItem
              description="Package Recommendation"
              imageAlternative={image.packageRecommendation}
              imageAlternativeName="Package Recommendation"
              repId={repId}
              val={repIdValue || ''}
              create={create}
              tool={tool}
              radioref={savetool}
              onClick={() => navigate('/package-recommendation')}
            />
          )}
          {frontendShowScans && (
            <ToolItem
              description="Thryv Business Analysis"
              imageAlternative={image.allInOneLogo}
              imageAlternativeName="All In One Logo"
              radioref={tRef}
              onClick={() => navigate('/scan-form')}
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvDemoRequest,
            roles,
          ) && <ToolItemThryvDemoRequest />}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvLeads,
            roles,
          ) && <ToolItemThryvLeadsWithModal />}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.seoProposal,
            roles,
          ) && (
            <ToolItem
              description="US SEO Proposal"
              imageAlternative={image.seoProposalMBG}
              imageAlternativeName="SEO Proposal Icon"
              radioref={tRef}
              redirectUrl="http://seodashboard.thryv.com/"
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.seoProposal,
            roles,
          ) && (
            <ToolItem
              description="AU/CA/NZ SEO Proposal"
              imageAlternative={image.seoProposalMBG}
              imageAlternativeName="SEO Proposal Icon"
              radioref={tRef}
              redirectUrl="https://seoproposaltool.thryv.com/us/login"
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvPaySavingsProposal,
            roles,
          ) && (
            <ToolItem
              description="ThryvPay Savings Proposal"
              imageAlternative={image.thryvPaySavingsProposal}
              imageAlternativeName="ThryvPay Savings Proposal"
              radioref={tRef}
              redirectUrl={REACT_APP_THRYV_PAY_SAVINGS_PROPOSAL_URL}
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvCSN,
            roles,
          ) && (
            <ToolItem
              description="Thryv CSN"
              imageAlternative={image.ooeSearchIcon}
              imageAlternativeName="ThryvPay Savings Proposal"
              radioref={tRef}
              redirectUrl="https://leads.thryv.com/"
            />
          )}
          <ToolItemPromotionalOffers />
          <ToolItemSalesPresentations />
          <ToolItemSalesBattleCards />
        </ItemsContainer>
        <br />
        <ItemsContainerTitle
          className="text-center"
          fontWeight="medium"
          variant="h2"
        >
          Educational Tools
        </ItemsContainerTitle>
        <br />
        <ItemsContainer>
          <ToolItem
            description="Thryv Support Center"
            imageAlternative={image.learnLogo}
            imageAlternativeName="Thryv Support Center"
            radioref={tRef}
            redirectUrl="https://learn.thryv.com/hc/en-us"
          />
          {getAuthByRole(
            EDUCATIONAL_TOOLS_AUTHORIZED_ROLES.thryvSpecialties,
            roles,
          ) && (
            <ToolItem
              description="Thryv Specialties"
              imageAlternative={image.specialitiesLogo}
              imageAlternativeName="Specialities Logo"
              radioref={tRef}
              redirectUrl="http://industries.thryv.info/"
            />
          )}
          <ToolItemThryvJobAids />
          <ToolItem
            description="Learning Hub"
            imageAlternative={image.learningHubLogo}
            imageAlternativeName="Learning Hub Logo"
            radioref={tRef}
            redirectUrl="https://thryv.sharepoint.com/teams/TheLearningHub/SitePages/CollabHome.aspx"
          />
          {getAuthByRole(
            EDUCATIONAL_TOOLS_AUTHORIZED_ROLES.thryvLeadsJobAids,
            roles,
          ) && <ToolItemThryvLeadsJobAids />}
          {getAuthByRole(
            EDUCATIONAL_TOOLS_AUTHORIZED_ROLES.thryvPayJobAids,
            roles,
          ) && <ToolItemThryvPayJobAids />}
          {getAuthByRole(
            EDUCATIONAL_TOOLS_AUTHORIZED_ROLES.marketingCenterJobAids,
            roles,
          ) && <ToolItemMarketingCenterJobAids />}
          <ToolItemClientTestimonials />
          <ToolItemCommandCenterDemos />
          <ToolItemBusinessCenterDemos />
          {getAuthByRole(
            EDUCATIONAL_TOOLS_AUTHORIZED_ROLES.marketingCenterDemo,
            roles,
          ) && <ToolItemMarketingCenterDemos />}
          <ToolItemThryvPayDemos />
          <ToolItemMarketplaceDemos />
          <ToolItemLeaveBehinds />
          <ToolItemSocialMediaGraphics />
          <ToolItemLocalAds />
        </ItemsContainer>
      </div>
    </div>
  )
}

export default ToolsContainer
